import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Link } from "rebass/styled-components";
import Axios from "axios";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Main from "../components/main";
import Text from "../components/text";
import GoogleMap from "../components/googleMap";

const infoFlexMobile = "1 0 100%";
const infoFlexTablet = "1 0 50%";

const Pharmacies = () => {
  const [allPharmacies, setAllPharmacies] = useState<any[]>([]);
  const [mapMarkers, setMapMarkers] = useState<any[]>([]);
  const [selectedPharmacyId, setSelectedPharmacyId] = useState<string>("");

  const getAllPharmacies = async () => {
    const { data } = await Axios.get(process.env.GATSBY_PHARMACIES_ENDPOINT!);
    return data;
  };

  useEffect(() => {
    if (!allPharmacies.length) {
      const initialiseMapMarkers = async () => {
        let pharmacies: any[] = [];

        try {
          pharmacies = await getAllPharmacies();
        } catch (error) {
          // If the patient's postcode could not be found, try again with a known postcode.
          if (error.response.status === 422) {
            try {
              pharmacies = await getAllPharmacies();
            } catch (innerError) {
              pharmacies = [];
            }
          }
        }

        const markers = pharmacies
          .filter(({ latitude }) => Boolean(latitude))
          .map(
            ({
              id,
              latitude,
              longitude,
              businessName,
              address1,
              address2,
              address3,
              city,
              postcode,
            }) => ({
              key: id,
              lat: latitude!,
              lng: longitude!,
              info: [businessName, address1, address2, address3, city, postcode]
                .filter(Boolean)
                .join("\n"),
            })
          );

        setAllPharmacies(pharmacies.map((p) => ({ ...p, id: p.pharmacyId })));
        setMapMarkers(markers);
      };
      initialiseMapMarkers();
    }
  }, [allPharmacies]);

  return (
    <>
      <SEO title="Participating pharmacies" />
      <Layout>
        <Main p={[3, 5, 5]} mb={4}>
          <Flex flexWrap="wrap">
            <Box flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]}>
              <GoogleMap
                markers={mapMarkers}
                labelColour='white'
                selectedMarker={mapMarkers.find(
                  ({ key }) => selectedPharmacyId === key
                )}
                onMarkerClick={(markerKey: string) => {
                  setSelectedPharmacyId(markerKey);
                }}
              />
            </Box>
            <Box
              flex={[infoFlexMobile, infoFlexTablet, infoFlexTablet]}
              px={5}
              height="500px"
              overflow="auto"
              mt={[3, 0, 0]}
            >
              {allPharmacies.map(
                (
                  {
                    key,
                    businessName,
                    address1,
                    address2,
                    address3,
                    city,
                    postcode,
                  },
                  i
                ) => (
                  <Box mt={i === 0 ? 0 : 4} key={key} id={key}>
                    <Text color="white" fontFamily="medium" fontSize={3}>
                      {businessName}
                    </Text>
                    <Text color="white">{address1}</Text>
                    <Text color="white">{address2}</Text>
                    <Text color="white">{address3}</Text>
                    <Text color="white">{city}</Text>
                    <Text color="white">{postcode}</Text>
                    <Button
                      variant="secondary"
                      color="primary"
                      mt={2}
                      mr={2}
                      onClick={() => setSelectedPharmacyId(key)}
                    >
                      <Text color="primary" fontFamily="bold">
                        View on map
                      </Text>
                    </Button>
                    {/* <Button
                      variant="secondary"
                      color="primary"
                      as={Link}
                      mt={2}
                      href={`/services?pharmacy=${key}`}
                    >
                      <Text color="primary" fontFamily="bold">
                        Book service
                      </Text>
                    </Button> */}
                  </Box>
                )
              )}
            </Box>
          </Flex>
          <Box mb={5} />
        </Main>
      </Layout>
    </>
  );
};

export default Pharmacies;
